var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "singlePictureBox", staticClass: "singlePicture" }, [
    _c(
      "div",
      {
        staticClass: "pictrueContainer",
        style: {
          width: `${_vm.singleContainerWidth}px`,
          height: `${_vm.singleContainerHeight}px`
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("selectPictrue", 0)
          }
        }
      },
      [
        _c("Picture", {
          attrs: { src: _vm.picturePath, className: _vm.pictureType },
          on: { imageLoadSuccess: _vm.imageLoadSuccess }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }