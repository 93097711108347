<template>
  <div class="singlePicture" ref="singlePictureBox">
    <div 
      class="pictrueContainer"
      :style="{width:`${singleContainerWidth}px`,height:`${singleContainerHeight}px`}"
      @click.stop="$emit('selectPictrue',0)"
    >
     
      <Picture :src="picturePath" :className="pictureType" @imageLoadSuccess="imageLoadSuccess"></Picture>
    </div>
  </div>
</template>
<script>
import Picture from "../pictureWidget"
export default {
  components: {
    Picture
  },
  props: {
    // 传入的图片集合
    pictures: {
      type: Array,
      default:() => {
        return [];
      }
    }
  },
  data() {
    return {
      // ==========================================竖向===================================
      // 单张图片竖向标准宽高比例（当竖向图片宽高比小于这个比例，说明这张图片的高超出了限制，反之宽超出了限制）
      verticalRatio: 225/300,
      // 单张竖向图片最大宽度
      verticalMaxWidth: 225,
      // 单张竖向图片最大高度
      verticalMaxHeight: 300,
      // ==========================================横向===================================
      // 单张图片横向标准宽高比例（当横向图片宽高比小于这个比例，说明在到达最大高度状态时这张图片的宽低于了限制，反之宽超出了限制）
      horizontalRatio: 300/200,
      // 单张横向图片最大宽度
      horizontalMaxWidth: 300,
      // 单张横向图片最大高度
      horizontalMaxHeight: 200,
      // ==========================================正方形===================================
      // 正方形最大边长
      squareSideLength: 225,
      // 图片类型
      pictureType: '',
      // 单张图片的容器宽度
      singleContainerWidth: 0,
      // 单张图片的容器高度
      singleContainerHeight: 0,
      // 父容器宽度
      parentContainerWidth: 0,
      // 父容器高度度
      parentContainerHeight: 0,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      // 获取父容器宽度
      this.parentContainerWidth = this.$refs.singlePictureBox.offsetWidth;
      this.parentContainerWidth&&localStorage.setItem('parentContainerWidth',this.parentContainerWidth);
      this.parentContainerWidth = this.$refs.singlePictureBox.offsetWidth || localStorage.getItem('parentContainerWidth');
      this.handleOnePicture(this.pictures[0])
    })
  },
  computed: {
    // 图片地址
    picturePath() {
      return this.pictures[0]?this.pictures[0]:'';
    },
    // 如果父容器宽度大于最大限制宽度则取最大限制宽度，反之取父容器宽度
    realVerticalWidth() {
      return this.parentContainerWidth >= this.verticalMaxWidth?this.verticalMaxWidth:this.parentContainerWidth
    },
  },
  methods: {
    // 图片加载成功
    imageLoadSuccess(src) {
      this.$emit('imageLoadSuccess',src);
    },
    // 处理单张图片比例
    handleOnePicture(pictureSrc) {
      this.getImgInfo(pictureSrc).then((image)=>{
        let pictureRatio = image.width/image.height;
        pictureRatio == 1 ?this.handleSquarePicture():pictureRatio < 1?this.handleVerticalPicture(image) : this.handleHorizontalPicture(image);
      })
    },
    // 处理正方形图片数据
    handleSquarePicture() {
      this.pictureType = 'squarePicture';
      // 获取图片容器的宽度
      this.singleContainerWidth = this.parentContainerWidth >= this.squareSideLength?this.squareSideLength:this.parentContainerWidth;
      // 获取图片容器的高度
      this.singleContainerHeight = this.parentContainerWidth >= this.squareSideLength?this.squareSideLength:this.parentContainerWidth;
    },
    // 处理单张竖向图片数据
    handleVerticalPicture(image) {
      let pictureRatio = image.width/image.height;
      this.pictureType = 'verticalPicture';
      // 获取父容器的最大高度
      this.parentContainerHeight = this.parentContainerWidth/this.verticalRatio;
      // 获取图片容器的宽度
      this.singleContainerWidth = this.realVerticalWidth;
      // 当图片的高超出了限制，则图片高度为最大限制高度
      if(pictureRatio < this.verticalRatio ) {
        // 根据已设定的宽度和预设的宽高比，去获取图片容器的高度
        this.singleContainerHeight = this.singleContainerWidth/this.verticalRatio;
      } else {
        // 根据已设定的宽度和图片自己的宽高比，去获取图片容器高度
        this.singleContainerHeight = this.singleContainerWidth/pictureRatio;
      }
    },
    // 处理单张横向图片数据
    handleHorizontalPicture(image) {
      let pictureRatio = image.width/image.height;
      this.pictureType = 'horizontalPicture';
      // 获取父容器的最大高度
      this.parentContainerHeight = this.parentContainerWidth/this.horizontalRatio;
      // 获取图片容器高度,当父容器高度大于或等于最大限制高度的时候，取最大限制高度作为图片容器高度,当小于最大限制高度时，则取父容器的最大高度为图片容器高度
      this.singleContainerHeight = this.parentContainerHeight >= this.horizontalMaxHeight? this.horizontalMaxHeight:this.parentContainerHeight;
      // 如果图片宽度大于预期最大限制的图片容器宽度，则将图片容器宽度设置为预设宽度
      // 当横向图片宽高比小于预设的宽高比例，说明在到达最大高度状态时这张图片的宽度低于这个高度对应比例的宽度，反之宽超出了对应比例
      if(pictureRatio < this.horizontalRatio) {
        // 根据已设定的高度和图片自己的宽高比，去获取对应的图片容器宽度
        this.singleContainerWidth = this.singleContainerHeight*pictureRatio;
      } else {
        // 根据已设定的高度和预设的宽高比，去获取图片容器的宽度
        this.singleContainerWidth = this.singleContainerHeight*this.horizontalRatio;
      }
    },
    // 获取当前图片的属性
    getImgInfo(pictureSrc) {
      return new Promise((resolve,reject)=>{
        let img = new Image();
        img.src = this.formartImageUrl(pictureSrc);;
        img.onload = ()=>{
          resolve(img)
        }
      })
    },
  }
}
</script>
<style lang="stylus" scoped>
  .singlePicture
    width 100%;
    overflow hidden;
    display flex;
    align-items flex-start;
    justify-content flex-start;
    .pictrueContainer
      border-radius 8px;
      overflow hidden;

</style>
<style lang="stylus">
  .squarePicture
    .el-image__inner
      object-position: center center;
  .verticalPicture
    .el-image__inner
      object-position: center top;
  .horizontalPicture
    .el-image__inner
      object-position: center center;

</style>