<template>
  <div class="multiplePictures" ref="multiplePictures">
    <div
      class="pictruesContainer"
      :style="{
        width: `${containerMaxWidth}px`,
        height: `${calculatedHeight}px`,
      }"
    >
      <div
        class="pictrueItem"
        v-for="(item, index) in handlePictures"
        :key="index"
        :style="{
          width: `${sideLength}px`,
          height: `${sideLength}px`,
          top: `${
            (columns == 2 && index < 2) || (columns == 3 && index < 3)
              ? 0
              : index < 6
              ? coordinateData
              : coordinateData * 2
          }px`,
          left: `${(index % columns) * coordinateData}px`,
        }"
        @click.stop="selectPictrue(index)"
      >
        <div class="lastMask" v-if="remainingUnshown != 0 && index == 8">
          +{{ remainingUnshown }}
        </div>
        <Picture :src="item" @imageLoadSuccess="imageLoadSuccess"></Picture>
      </div>
    </div>
  </div>
</template>
<script>
import Picture from "../pictureWidget";
export default {
  components: {
    Picture,
  },
  props: {
    // 传入的图片集合
    pictures: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // 最外层父容器宽度
      parentContainerWidth: 0,
      // 容器最大限制宽度
      containerLimitWidth: 0,
      // 容器最大限制高度
      calculatedHeight: 0,
      // 图片容器父容器宽度
      pictruesContainerWidth: 0,
      // 默认列数
      columns: 3,
      // 图片加载计数
      imageLoadCount: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取父容器宽度
      this.parentContainerWidth = this.$refs.multiplePictures.offsetWidth;
      this.parentContainerWidth &&
        localStorage.setItem("parentContainerWidth", this.$refs.multiplePictures.offsetWidth);
      this.parentContainerWidth =
        this.$refs.multiplePictures.offsetWidt ||
        localStorage.getItem("parentContainerWidth");
      this.calculateTheWidth(this.pictures);
    });
  },
  computed: {
    // 需要展示在组件上的图片集合
    handlePictures() {
      return this.pictures.slice(0, 9);
    },
    // 最后一个图片需要展示还剩多少张没展示
    remainingUnshown() {
      return this.handlePictures.length < this.pictures.length
        ? this.pictures.length - this.handlePictures.length
        : 0;
    },
    // 动态计算容器最大宽度
    containerMaxWidth() {
      return this.parentContainerWidth >= this.containerLimitWidth
        ? this.containerLimitWidth
        : this.parentContainerWidth;
    },
    // 动态计算图片容器的宽度(高度)
    sideLength() {
      return (this.containerMaxWidth - (this.columns - 1) * 4) / this.columns;
    },
    // 动态计算的坐标
    coordinateData() {
      return this.sideLength + 4;
    },
  },
  methods: {
    // 点击选择这张图片
    selectPictrue(index) {
      this.$emit("selectPictrue", index);
    },
    // 图片加载成功
    imageLoadSuccess(src) {
      this.imageLoadCount++;
      if (this.imageLoadCount === this.handlePictures.length) {
        this.$emit("imageLoadSuccess", src);
      }
    },
    // 计算最大限制宽度
    calculateTheWidth(pictures) {
      if (pictures.length == 2 || pictures.length == 4) {
        this.containerLimitWidth = 284;
        // 设置列数
        this.columns = 2;
      } else if (pictures.length == 3 || pictures.length > 4) {
        this.containerLimitWidth = 428;
        // 设置列数
        this.columns = 3;
      }
      this.$nextTick(() => {
        this.calculateTheHeight(pictures);
      });
    },
    // 根据图片数量动态计算最大限制高度
    calculateTheHeight(pictures) {
      if (pictures.length <= 3) {
        this.calculatedHeight = this.sideLength;
      } else if (pictures.length > 3 && pictures.length <= 6) {
        this.calculatedHeight = this.sideLength * 2 + 4;
      } else if (pictures.length > 6) {
        this.calculatedHeight = this.sideLength * 3 + 8;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.multiplePictures {
  width: 100%;
  overflow: hidden;

  .pictruesContainer {
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .pictrueItem {
      z-index: 1;
      overflow: hidden;
      position: absolute;
      box-sizing: border-box;

      .lastMask {
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        cursor: pointer;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>